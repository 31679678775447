<template>
	<div class="layout-default container">
		<AsideMenu />

		<slot />
	</div>
</template>

<style scoped lang="scss">
	@import "@/assets/scss/layouts/default";
</style>
